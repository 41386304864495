






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { db } from '@/firebase';
import { Message } from 'element-ui';

@Component
export default class ReviewList extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  items!: any;

  questions: { name: string; items: any[]; visible: boolean } = { name: '', items: [], visible: false };
  popoverDelete: any = {};

  showQuestions(name: string, ids: string[] = []) {
    this.questions.name = name;
    this.questions.items = [];
    for (const id of ids) {
      db.collection('LearningFeature')
        .doc(id)
        .get()
        .then((DocumentSnapshot) => {
          if (DocumentSnapshot.data()) {
            this.questions.items.push(DocumentSnapshot.data());
          }
        });
    }
    this.questions.visible = true;
  }

  removeExam(index: number, id: string) {
    db.collection('LearningFeatureExams')
      .doc(id)
      .delete()
      .then(() => {
        Message({
          message: 'Successfully removed the exam',
          type: 'success',
        });
        this.items.splice(index, 1);
        this.popoverDelete[id] = false;
      })
      .catch(() =>
        Message({
          message: 'Something went wrong, please try again',
          type: 'error',
        }),
      );
  }
}
