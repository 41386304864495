
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { db } from '@/firebase';
import ReviewList from './components/ReviewList.vue';

@Component({
  components: {
    ReviewList,
  },
})
export default class LearningFeatureBuilderReview extends Vue {
  loading = false;
  items: any[] = [];
  activeTab = 'Sergeant';

  sergeantItems: any[] = [];
  lieutenantItems: any[] = [];
  captainItems: any[] = [];

  @Watch('items', { deep: true })
  updateRanks() {
    this.sergeantItems = this.items.filter((item) => item.class === 'sergeant');
    this.lieutenantItems = this.items.filter((item) => item.class === 'lieutenant');
    this.captainItems = this.items.filter((item) => item.class === 'captain');
  }

  created() {
    this.loading = true;
    db.collection('LearningFeatureExams')
      .get()
      .then((querySnapshot: any) => {
        querySnapshot.forEach((item: any) => {
          const examId = { objectId: item.id };
          const exam = item.data() || {};
          this.items.push({ ...exam, ...examId });
        });
        this.loading = false;
      });
  }
}
